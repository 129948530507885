@import './variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import './mixins';
@import 'settings/variables';

// Modules
@import './experience/components/omni_assets/heroBanner';
@import './experience/components/omni_assets/sectionContent';

.login-page {
    padding-bottom: rem-calc(50);
    padding-top: rem-calc(34);

    .card {
        border: 0;
        width: 100%;
    }

    .card-body {
        padding: 0;
    }

    &__forms {
        padding: rem-calc(34) 0;

        .nav-tabs {
            border: 0;
            margin-bottom: rem-calc(34);

            .nav-link {
                border: 0;
                color: $regular-grey;
                display: inline;
                font-family: $font-serif;
                font-size: rem-calc(22);
                font-weight: 400;
                letter-spacing: 0;
                line-height: rem-calc(37);
                padding: 0;
                transition: color 0.3s ease-out;

                &::after {
                    background-color: $secondary;
                    bottom: rem-calc(-5);
                    height: rem-calc(2);
                    transition: width 0.4s ease-out;
                    width: 0;
                }

                &:hover {
                    color: $secondary;

                    &::after {
                        width: 100%;
                    }
                }

                &.active {
                    color: $accent;

                    &:hover {
                        color: $accent;
                    }

                    &::after {
                        display: none;
                    }
                }

                @include media-breakpoint-up(sm) {
                    font-size: rem-calc(30);
                }
            }
        }

        .joiningOpt__label {
            letter-spacing: 0.0525rem;

            @include media-breakpoint-up(xl) {
                font-size: rem-calc(15) !important;
            }
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(42) rem-calc(42);
        }
    }

    &__order {
        border-radius: rem-calc(10);
        box-shadow: 0 0 rem-calc(6) rgba(166, 166, 166, 0.5);
        margin-top: rem-calc(29);
        padding: rem-calc(31 20 43);
        position: relative;

        &::before {
            background: $secondary;
            border-radius: rem-calc(100);
            content: '';
            display: block;
            height: rem-calc(11);
            left: 0;
            position: absolute;
            top: rem-calc(-29);
            width: 100%;
        }

        .card-header {
            @include heading($sm-font-size: 22, $md-font-size: 30, $xxl-font-size: 30);

            border: 0;
            padding: 0;
        }

        .track-order-header-text {
            @include description($md-font-size: 16);
        }

        @include media-breakpoint-up(md) {
            padding: rem-calc(50 60);
        }
    }

    &__col {
        &--left {
            max-width: rem-calc(683);
        }

        &--right {
            max-width: rem-calc(545);
        }
    }

    .privacy-policy {
        @include description($sm-font-size: 14, $multiple-size: false);

        margin-top: rem-calc(30);

        a {
            font: inherit;
            font-weight: 400;
            line-height: inherit;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem-calc(50);
    }
}

.password-constraints {
    @include description($sm-font-size: 14, $multiple-size: false);

    margin-top: rem-calc(30);
}

.hero-banner {
    &__bg {
        img {
            object-position: center bottom;
        }

        .narrow & {
            @include media-breakpoint-down(sm) {
                height: auto;
                min-height: auto;
            }
        }
    }

    &.narrow {
        @include media-breakpoint-between(md, lg) {
            height: 40vw;
        }
    }
}

.password-reset,
.private-events {
    margin-top: rem-calc(100);
    padding-bottom: rem-calc(60);
    padding-top: rem-calc(60);

    form {
        textarea {
            min-height: rem-calc(200);
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(138);
        padding-bottom: rem-calc(120);
        padding-top: rem-calc(120);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(120);
    }
}

.section-intro {
    margin-bottom: rem-calc(54);
    text-align: center;

    &__heading {
        @include heading();
    }

    &__description {
        @include description($md-font-size: 24);

        letter-spacing: normal;
        padding: 0 rem-calc(5);

        @include media-breakpoint-up(md) {
            padding: 0 rem-calc(15);
        }

        @include media-breakpoint-up(xl) {
            padding: 0 rem-calc(30);
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(88);
    }
}

.section-sidebar {
    @include media-breakpoint-down(sm) {
        margin-top: rem-calc(60);
    }

    @include media-breakpoint-up(xl) {
        max-width: rem-calc(350);
    }
}

.login-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include heading($sm-font-size: 22, $md-font-size: 30, $xxl-font-size: 30);

        margin-bottom: 2em;

        @include media-breakpoint-up(lg) {
            margin-bottom: 2em;
        }
    }

    p {
        @include description();

        font-weight: 400;
        letter-spacing: normal;

        a {
            font: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem-calc(75);
        max-width: rem-calc(1200);
        padding-left: rem-calc(44);
    }
}

.joining-link {
    color: $accent;
    font-family: $font-sans-serif;
    font-weight: bold;
    position: relative;
    text-decoration: underline;
}

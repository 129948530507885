@import '~site/variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import '~site/settings/variables';
@import '~site/mixins';

.section-content {
    margin-bottom: rem-calc(45);
    margin-top: rem-calc(45);

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
        color: $primary;
        font-family: $font-serif;
    }

    p {
        @include description($color: $primary);

        letter-spacing: normal;

        a {
            font: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    ul,
    ol {
        @include description($color: $primary);

        letter-spacing: normal;
        padding-left: rem-calc(23);

        > ul,
        > ol {
            padding-left: rem-calc(38);
        }

        li {
            margin-bottom: 0.5em;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(90);
        margin-top: rem-calc(90);
    }
}

.section-sidebar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary;
        font-family: $font-serif;
        margin-bottom: 1.5em;
    }

    p {
        @include description($sm-font-size: 18, $color: $primary, $multiple-size: false);

        letter-spacing: normal;
    }

    a:not(.btn) {
        font: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    dl {
        margin-bottom: 1.5em;
    }

    dd {
        @include description($sm-font-size: 18, $color: $primary, $multiple-size: false);

        letter-spacing: normal;
        margin-bottom: 0;
    }
}

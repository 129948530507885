@import '~site/variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import '~site/settings/variables';
@import '~site/mixins';

$btn-gutter-xl: 23;
$btn-gutter-xxl: 32;

.hero-banner {
    position: relative;

    &__bg {
        background-color: $offdark;
        height: rem-calc(650);
        max-height: 100vh;
        min-height: rem-calc(340);
        position: relative;
        width: 100%;
        z-index: 0;

        .has-header-banner & {
            @include media-breakpoint-down(sm) {
                max-height: calc(100vh - 34px);
            }
        }

        .plp & {
            height: rem-calc(253);
            min-height: rem-calc(253);

            @include media-breakpoint-up(md) {
                height: 100%;
                min-height: rem-calc(400);
            }
        }

        .narrow & {
            @include media-breakpoint-up(md) {
                min-height: rem-calc(400);
            }
        }

        &::after {
            background: linear-gradient(180deg, $primary 47%, transparent 100%);
            content: '';
            height: rem-calc(267);
            left: 0;
            opacity: 0.65;
            position: absolute;
            top: 0;
            width: 100%;

            .gradient-narrow-light & {
                background: linear-gradient(180deg, $white-rock 50%, rgba(251, 247, 238, 0) 88.74%);
            }

            .gradient-full & {
                height: 100%;
                opacity: 0.7;
            }

            .narrow & {
                height: 61%;
            }

            .plp & {
                height: 54%;
            }
        }

        @include media-breakpoint-up(md) {
            height: 100%;
            min-height: rem-calc(800);
            position: absolute;
        }
    }

    &__img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__logo {
        height: rem-calc(80);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__text {
        align-items: center;
        background-color: $light-white9;
        display: flex;
        justify-content: center;
        padding-bottom: rem-calc(43);
        padding-top: rem-calc(67);
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            background-color: transparent;
            height: 100%;
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    &__heading {
        @include heading($sm-font-size: 26, $sm-line-height: 1.35, $md-line-height: 1.35, $xxl-line-height: 1.35);

        margin-bottom: rem-calc(3);

        .plp & {
            @include media-breakpoint-up(md) {
                font-size: rem-calc(60);
                letter-spacing: normal;
                line-height: 1.3;
            }
        }

        @include media-breakpoint-up(md) {
            color: $white-rock;
            font-size: rem-calc(50);
            letter-spacing: rem-calc(-1);
            margin-bottom: rem-calc(8);
            position: relative;
            z-index: 1;
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(60);
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(64);
        }
    }

    &__subheading {
        @include description($md-font-size: 24, $md-line-height: 1.2, $multiple-size: false);

        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            color: $white-rock;
            position: relative;
            z-index: 1;
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(26);
            line-height: 1.34;
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(24);
            line-height: 1.2;
        }
    }

    &__scroll {
        bottom: 0;
        height: 26.8%;
        left: 50%;
        min-height: rem-calc(168);
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        width: 100%;
        z-index: 1;

        &::after,
        &::before {
            bottom: 0;
            content: '';
            left: 50%;
            position: absolute;
            width: rem-calc(3);
        }

        &::after {
            background-color: $accent;
            border-radius: rem-calc(0 0 2 2);
            height: 21.25%;
            transform: translate(-50%, 100%);

            @include media-breakpoint-up(md) {
                height: 26.92%;
            }
        }

        &::before {
            background-color: $light;
            border-radius: rem-calc(2 2 0 0);
            height: 54%;
            transform: translate(-50%, 0);

            @include media-breakpoint-up(md) {
                height: 69.23%;
            }
        }

        span {
            @include description($sm-font-size: 14, $sm-line-height: 1.35, $color: $light, $multiple-size: false);

            cursor: pointer;
            font-weight: 500;
            letter-spacing: rem-calc(2);
            margin-bottom: 0;
            text-transform: uppercase;

            &::after {
                background-color: $light;
                border-radius: 50%;
                bottom: 63%;
                content: '';
                height: rem-calc(5);
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: rem-calc(5);

                @include media-breakpoint-up(md) {
                    bottom: 75%;
                }
            }

            @include media-breakpoint-up(md) {
                letter-spacing: rem-calc(2);
            }
        }

        .plp & {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            min-height: rem-calc(196);
        }
    }

    &__buttons {
        margin-top: rem-calc(34);

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: rem-calc(-$btn-gutter-xl / 2);
            margin-right: rem-calc(-$btn-gutter-xl / 2);
            margin-top: rem-calc(38);
            position: relative;
            row-gap: 10px;
            z-index: 1;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: rem-calc(-$btn-gutter-xxl / 2);
            margin-right: rem-calc(-$btn-gutter-xxl / 2);
            margin-top: rem-calc(63);
        }
    }

    &__btn {
        +.btn {
            margin-top: rem-calc(20);

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(md) {
            @include button-outline($white-rock);
        }

        @include media-breakpoint-up(md) {
            display: inline-block;
            font-size: rem-calc(14);
            font-weight: 600;
            margin-left: rem-calc($btn-gutter-xl / 2);
            margin-right: rem-calc($btn-gutter-xl / 2);
            min-width: rem-calc(200);
            padding-bottom: rem-calc(14);
            padding-top: rem-calc(14);

            &:hover {
                background: linear-gradient(to left, transparent 50%, $white-rock 50%);
                background-color: transparent;
                background-position: left center;
                background-size: 205% 105%;
            }
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(16);
            font-weight: 700;
            margin-left: rem-calc($btn-gutter-xxl / 2);
            margin-right: rem-calc($btn-gutter-xxl / 2);
            min-width: rem-calc(226);
        }
    }

    &__links {
        margin-top: rem-calc(24);

        @include media-breakpoint-up(md) {
            position: relative;
            z-index: 1;
        }
    }

    &__btn-link {
        @include button-link();

        align-items: center;
        display: flex;
        font-size: rem-calc(14);
        justify-content: center;
        letter-spacing: rem-calc(1.6);
        line-height: rem-calc(20);
        min-height: rem-calc(52);
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            color: $white-rock;
            font-size: rem-calc(16);
            font-weight: 600;

            &:hover {
                color: adjust-color($white-rock, $lightness: 5%);
            }
        }
    }

    .has-header-banner & {
        @include media-breakpoint-up(md) {
            max-height: calc(100vh - 34px);
        }

        @include media-breakpoint-up(lg) {
            max-height: calc(100vh - 41px);
        }
    }

    &.plp {
        @include media-breakpoint-up(md) {
            height: rem-calc(610);
            min-height: rem-calc(400);
        }
    }

    &.narrow {
        @include media-breakpoint-up(md) {
            height: rem-calc(664);
            min-height: rem-calc(400);
        }
    }

    @include media-breakpoint-up(md) {
        height: rem-calc(1200);
        margin-bottom: rem-calc(73);
        max-height: 100vh;
        min-height: rem-calc(750);
    }
}
